import './App.scss';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MainScreen from './containers/MainScreen';
import AuthScreen from './containers/AuthScreen';
import {onAuthStateChanged} from './firebase';
import {setUser, resetUser} from './actions';

function App({user, setUser, resetUser}) {
    useEffect(() => {
        onAuthStateChanged((userData) => {
            if (userData) {
                const {multiFactor: {user}} = userData;
                setUser(user);
            } else {
                // User is signed out
                resetUser();
            }
        });
    }, [resetUser, setUser]);

    return (
        <div className="container">
            <div className="main-box">
                {user.loaded && user.uid ? (
                    <MainScreen />
                ) : null}
                {user.loaded && !user.uid ? (
                    <AuthScreen />
                ) : null}
                {!user.loaded ? (
                    <p>Loading...</p>
                ) : null}
            </div>
        </div>
    );
}

App.propTypes = {
    user: PropTypes.object.isRequired,
    setUser: PropTypes.func,
    resetUser: PropTypes.func,
};

const mapStateToProps = (state) => {
    const {user} = state;

    return {user};
};

const mapDispatchToProps = {
    setUser,
    resetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
