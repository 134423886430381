import * as R from 'ramda';
import {SET_USER, RESET_USER} from '../actionTypes';

const initialState = {
    loaded: false,
    uid: null,
    email: null,
    emailVerified: false,
    displayName: null,
};

let serviceData = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_USER:
            return R.mergeRight(state, {
                loaded: true,
                ...payload,
            });
        case RESET_USER:
            return R.mergeRight(initialState, {
                loaded: true,
            });
        default:
            return state
    }
}

export default serviceData;
