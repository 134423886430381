import React, {useState, useCallback} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {signIn, signUp, db} from '../firebase';
import {initialServiceData} from '../firebase/initialData';

const initialValues = {
    email: '',
    password: '',
};
const validation = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
    password: Yup.string().required('Password is required').min(6, 'The password must be 6 characters long or more.'),
});

function AuthScreen() {
    const [isLoginScreen, setIsLoginScreen] = useState(true);
    const setLoginScreen = useCallback(() => setIsLoginScreen(true), []);
    const setRegisterScreen = useCallback(() => setIsLoginScreen(false), []);

    const formikSignIn = useFormik({
        initialValues,
        validationSchema: validation,
        onSubmit: values => signIn(values.email, values.password).catch((error) => handleErrors(error, formikSignIn)),
    });

    const formikSignUp = useFormik({
        initialValues,
        validationSchema: validation,
        onSubmit: values => signUp(values.email, values.password).then((userData) => {
            const {user: {multiFactor: {user}}} = userData;

            db().collection("users").doc(user.uid).set({
                uid: user.uid,
                email: user.email,
            }).then(function () {
                db().collection("serviceData").doc(user.uid).set(initialServiceData).catch((error) => console.log('error', error));
            });

        }).catch((error) => handleErrors(error, formikSignUp)),
    });

    const handleErrors = (error, form) => {
        if (error.code === 'auth/user-not-found') {
            form.setErrors({email: error.message});
        }

        if (error.code === 'auth/wrong-password' || error.code === 'auth/weak-password') {
            form.setErrors({password: error.message});
        }
    }

    return (
        <div className="main-box__content main-box__content--default">
            {isLoginScreen ? (
                <form onSubmit={formikSignIn.handleSubmit}>
                    <h1 className={'text-info'}>Login</h1>
                    <div className="c-form">
                        <div className="c-form__group">
                            <label htmlFor="email" className={'c-form__label'}>Email <span
                                className={'text-danger'}>*</span></label>
                            <input type="email" className={'gui-text-field'} id={'email'} name={'email'}
                                   onChange={formikSignIn.handleChange}
                                   onBlur={formikSignIn.handleBlur}
                                   value={formikSignIn.values.email}/>
                            {formikSignIn.touched.email && formikSignIn.errors.email ? (
                                <div className="text-danger u-mt-10 u-text-12"><b>{formikSignIn.errors.email}</b></div>
                            ) : null}
                        </div>
                        <div className="c-form__group">
                            <label htmlFor="password" className={'c-form__label'}>Password <span
                                className={'text-danger'}>*</span></label>
                            <input type="password" className={'gui-text-field'} id={'password'} name={'password'}
                                   onChange={formikSignIn.handleChange}
                                   onBlur={formikSignIn.handleBlur}
                                   value={formikSignIn.values.password}/>
                            {formikSignIn.touched.password && formikSignIn.errors.password ? (
                                <div className="text-danger u-mt-10 u-text-12"><b>{formikSignIn.errors.password}</b>
                                </div>
                            ) : null}
                        </div>
                        <div className="c-form__group">
                            <button type={'submit'} className={'gui-button gui-button--block'}>Login</button>
                        </div>
                        <div className="c-form__group text-info">
                            Don't have an account yet? <button type={'button'} className={'gui-link'}
                                                               onClick={setRegisterScreen}>Register</button>
                        </div>
                    </div>
                </form>
            ) : (
                <form onSubmit={formikSignUp.handleSubmit}>
                    <h1 className={'text-info'}>Register</h1>
                    <div className="c-form">
                        <div className="c-form__group">
                            <label htmlFor="email" className={'c-form__label'}>Email <span
                                className={'text-danger'}>*</span></label>
                            <input type="email" className={'gui-text-field'} id={'email'} name={'email'}
                                   onChange={formikSignUp.handleChange}
                                   onBlur={formikSignUp.handleBlur}
                                   value={formikSignUp.values.email}/>
                            {formikSignUp.touched.email && formikSignUp.errors.email ? (
                                <div className="text-danger u-mt-10 u-text-12"><b>{formikSignUp.errors.email}</b></div>
                            ) : null}
                        </div>
                        <div className="c-form__group">
                            <label htmlFor="password" className={'c-form__label'}>Password <span
                                className={'text-danger'}>*</span></label>
                            <input type="password" className={'gui-text-field'} id={'password'} name={'password'}
                                   onChange={formikSignUp.handleChange}
                                   onBlur={formikSignUp.handleBlur}
                                   value={formikSignUp.values.password}/>
                            {formikSignUp.touched.password && formikSignUp.errors.password ? (
                                <div className="text-danger u-mt-10 u-text-12"><b>{formikSignUp.errors.password}</b>
                                </div>
                            ) : null}
                        </div>
                        <div className="c-form__group">
                            <button type={'submit'} className={'gui-button gui-button--block'}>Register</button>
                        </div>
                        <div className="c-form__group text-info">
                            Have got an account? <button type={'button'} className={'gui-link'}
                                                         onClick={setLoginScreen}>Login</button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    )
}

export default AuthScreen;
